import Icon from '@chakra-ui/icon';
import { chakra } from '@chakra-ui/react';
import React from 'react';

type DiscordIconProps = {
  className?: string;
  width?: string;
  height?: string;
  color?: string;
};

export default chakra(function DiscordRoundedIcon({
  className,
  width,
  height,
  color,
}: DiscordIconProps) {
  return (
    <Icon
      className={className}
      viewBox='0 0 24 24'
      width={width || '24px'}
      height={height || '24px'}
      color={color || 'white'}
    >
      <title>Discord Logo</title>
      <rect width='24' height='24' rx='12' fill='#0206A8' />
      <path
        d='M10.396 11.3125C10.0347 11.3125 9.75 11.6292 9.75 12.0158C9.75 12.4012 10.0413 12.7192 10.396 12.7192C10.7567 12.7192 11.0413 12.4012 11.0413 12.0158C11.0487 11.6285 10.7567 11.3125 10.396 11.3125ZM12.708 11.3125C12.3467 11.3125 12.062 11.6292 12.062 12.0158C12.062 12.4012 12.3533 12.7192 12.708 12.7192C13.0687 12.7192 13.3533 12.4012 13.3533 12.0158C13.3527 11.6285 13.0687 11.3125 12.708 11.3125Z'
        fill='currentColor'
      />
      <path
        d='M15.7853 6H7.298C6.58267 6 6 6.58267 6 7.30467V15.8673C6 16.5893 6.58267 17.172 7.298 17.172H14.48L14.1433 15.9993L14.9547 16.7533L15.7207 17.4627L17.0833 18.6667V7.30467C17.0833 6.58267 16.5007 6 15.7853 6ZM13.34 14.2713C13.34 14.2713 13.112 13.9993 12.9227 13.7573C13.752 13.5227 14.0687 13.004 14.0687 13.004C13.8087 13.1747 13.562 13.296 13.34 13.3787C13.0233 13.5107 12.72 13.5993 12.422 13.6507C11.814 13.764 11.2567 13.7327 10.7813 13.644C10.4207 13.574 10.1107 13.4727 9.85067 13.3707C9.70533 13.314 9.546 13.2453 9.38867 13.1567C9.36933 13.1427 9.35067 13.1373 9.332 13.1247C9.31867 13.118 9.31267 13.112 9.306 13.1047C9.192 13.042 9.12867 12.998 9.12867 12.998C9.12867 12.998 9.43267 13.5047 10.2373 13.7453C10.0473 13.9853 9.81267 14.2713 9.81267 14.2713C8.41333 14.2267 7.882 13.3087 7.882 13.3087C7.882 11.2693 8.794 9.61667 8.794 9.61667C9.706 8.932 10.5733 8.95133 10.5733 8.95133L10.6367 9.02733C9.49667 9.35733 8.97067 9.85733 8.97067 9.85733C8.97067 9.85733 9.11067 9.78133 9.34467 9.674C10.022 9.37667 10.56 9.294 10.782 9.27467C10.82 9.26867 10.852 9.262 10.89 9.262C11.2767 9.21133 11.7133 9.19867 12.1687 9.24933C12.7707 9.31933 13.4173 9.49667 14.0753 9.85733C14.0753 9.85733 13.5747 9.38267 12.498 9.05333L12.5867 8.952C12.5867 8.952 13.4547 8.93267 14.366 9.61733C14.366 9.61733 15.278 11.27 15.278 13.3093C15.278 13.3087 14.74 14.2267 13.34 14.2713Z'
        fill='currentColor'
      />
    </Icon>
  );
});
