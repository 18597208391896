import Icon from '@chakra-ui/icon';
import { chakra } from '@chakra-ui/react';
import React from 'react';

type ContactIconProps = {
  className?: string;
  width?: string;
  height?: string;
  color?: string;
};

export default chakra(function ContactRoundedIcon({
  className,
  width,
  height,
  color,
}: ContactIconProps) {
  return (
    <Icon
      className={className}
      viewBox='0 0 24 24'
      width={width || '24px'}
      height={height || '24px'}
      color={color || 'white'}
    >
      <title>Conact Sales Logo</title>
      <rect width='24' height='24' rx='12' fill='#0206A8' />
      <path
        d='M12.0007 5.33203C8.32465 5.33203 5.33398 8.3227 5.33398 11.9987V12.4747V14.6654V14.7607C5.33398 15.4434 5.93198 15.9987 6.66732 15.9987H7.33398C7.70265 15.9987 8.00065 15.7 8.00065 15.332V11.9034C8.00065 11.5354 7.70265 11.2367 7.33398 11.2367H6.72865C7.09932 8.6567 9.31932 6.66536 12.0007 6.66536C14.682 6.66536 16.902 8.6567 17.2727 11.2367H16.6673C16.2987 11.2367 16.0007 11.5354 16.0007 11.9034V14.6654V15.332V15.9987C16.0007 16.734 15.4027 17.332 14.6673 17.332H13.334V16.6654H10.6673V18.6654H12.0007H13.334H14.6673C16.138 18.6654 17.334 17.4694 17.334 15.9987C18.0693 15.9987 18.6673 15.4434 18.6673 14.7607V14.6654V12.4747V11.9987C18.6673 8.3227 15.6767 5.33203 12.0007 5.33203Z'
        fill='currentColor'
      />
    </Icon>
  );
});
