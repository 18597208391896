import React, { PropsWithChildren, useRef, useState } from 'react';
import ContentContainer from '../../components/ContentContainer';
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  useMediaQuery,
  Grid,
  GridItem,
  Button,
} from '@chakra-ui/react';
import DiscordRoundIcon from '../../icons/DiscordRoundIcon';
import Link from '../../components/Link';
import ContactSalesRoundedIcon from '../../icons/ContactSalesRoundedIcon';
import _copy from '../../copy/tryNow';
import useCopy from '../../hooks/useCopy';

type IconLinkProps = PropsWithChildren<{
  to: string;
  text: string;
}>;

const IconLink = ({ children, to, text }: IconLinkProps) => (
  <Link to={to}>
    <Flex>
      {children}
      <Text ml='8px' color='black'>
        {text}
      </Text>
    </Flex>
  </Link>
);

const Feedback = ({
  src,
  feedback,
  company,
  name,
}: {
  src: string;
  feedback: string;
  company: string;
  name: string;
}) => (
  <Flex flexDir='column' gap='10px' mt='10px'>
    <Box h='24px'>
      <Image src={src} maxH='100%' h='24px' />
    </Box>
    
    <Text color='white' fontSize='16px' lineHeight='24px'>
      “{feedback}”
    </Text>
    <Text color='white' fontWeight={700} fontSize='16px' lineHeight='20px'>
      {name} - {company}
    </Text>
  </Flex>
);

export default function TryNow() {
  const [isSmallerThan768] = useMediaQuery('(max-width: 768px)');
  const copy = useCopy(_copy);
  const [currPlatform, setCurrPlatform] = useState('');
  const [link, setLink] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const { title, subtitle, feedback, platforms } = copy;

  return (
    <Box bg='#EAF0F6' minH='calc(100vh - 65px)'>
      <ContentContainer noPadding={isSmallerThan768}>
        <Flex gap='24px' flexDir={{ base: 'column', md: 'row' }}>
          <Box height='0%'>
            <Box
              bg='linear-gradient(90deg, #0206A8 21.94%, #00EB9B 100%)'
              pt='90px'
              w={{ base: '100%', md: '640px', lg: '768px' }}
            >
              <Box
                bg='linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%)'
                px='32px'
                pb='32px'
                h='100%'
              >
                <Heading as='h1' size='h1' color='white' fontSize='2rem'>
                  {title}
                </Heading>
                <Text color='white' lineHeight='24px' mt='10px'>
                  {subtitle}
                </Text>
              </Box>
            </Box>
            <Box
              bg='white'
              p='32px'
              w={{ base: '100%', md: '640px', lg: '768px' }}
            >
              <Text
                color='aprysePrimaryFontGray'
                fontWeight='semibold'
                lineHeight='24px'
                mb='16px'
                textTransform='uppercase'
              >
                Select Platform
              </Text>
              <Grid
                templateColumns={{
                  base: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                }}
                gap='16px'
              >
                {Object.keys(platforms).map((platform, index) => (
                  <GridItem key={index}>
                    <Button
                      bg='transparent'
                      height='auto'
                      width='100%'
                      display='flex'
                      flexDir='column'
                      alignItems='center'
                      justifyContent='center'
                      borderRadius='0px'
                      onClick={() => {
                        if (currPlatform === platform) return;
                        setCurrPlatform(platform);
                        setLink('');
                        containerRef.current?.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }}
                      gap='9.5px'
                      outline={
                        currPlatform === platform
                          ? '2px solid #282BB5'
                          : '1px solid rgba(0, 0, 0, 0.08)'
                      }
                      py={4}
                      cursor='pointer'
                      _focus={{
                        bgColor: 'rgba(0, 0, 0, 0.08)',
                      }}
                    >
                      <Image src={platforms[platform].icon} w='32px' h='32px' />
                      <Text
                        color='apryseNavy'
                        fontSize='16px'
                        lineHeight='24px'
                        fontWeight={400}
                      >
                        {platforms[platform].name}
                      </Text>
                    </Button>
                  </GridItem>
                ))}
              </Grid>
              {currPlatform.length > 0 && (
                <Box mt='32px' ref={containerRef}>
                  <Text
                    color='aprysePrimaryFontGray'
                    lineHeight='24px'
                    mb='16px'
                    fontWeight='semibold'
                    fontSize='16px'
                  >
                    Get started on:
                  </Text>
                  <Flex flexDir='column' gap={4}>
                    {Object.keys(platforms[currPlatform].items).map(
                      (item, index) => (
                        <Box key={index}>
                          {
                            !platforms[currPlatform].hideTitle &&
                            <Text
                              textTransform='uppercase'
                              color='apryseIndigo'
                              fontSize='16px'
                              lineHeight='24px'
                              fontWeight='500'
                              mb={4}
                            >
                              {item}
                            </Text>
                          }
                          
                          <Grid
                            templateColumns={{
                              base: 'repeat(2, 1fr)',
                              sm: 'repeat(4, 1fr)',
                              lg: 'repeat(5, 1fr)',
                            }}
                            gap='16px'
                          >
                            {platforms[currPlatform].items[item].map(
                              (i, index) => (
                                <GridItem key={index}>
                                  <Button
                                    bg='transparent'
                                    height='auto'
                                    width='100%'
                                    display='flex'
                                    flexDir='column'
                                    alignItems='center'
                                    justifyContent='center'
                                    borderRadius='0px'
                                    onClick={() => setLink(i.link)}
                                    gap='9.5px'
                                    outline={
                                      link === i.link
                                        ? '2px solid #282BB5'
                                        : '1px solid rgba(0, 0, 0, 0.08)'
                                    }
                                    py={4}
                                    cursor='pointer'
                                    _focus={{
                                      bgColor: 'rgba(0, 0, 0, 0.08)',
                                    }}
                                  >
                                    <Image src={i.logo} w='32px' h='32px' />
                                    <Text
                                      color='apryseNavy'
                                      fontSize='16px'
                                      lineHeight='24px'
                                      fontWeight={400}
                                    >
                                      {i.text}
                                    </Text>
                                  </Button>
                                </GridItem>
                              )
                            )}
                          </Grid>
                        </Box>
                      )
                    )}
                  </Flex>
                </Box>
              )}
              <Flex justifyContent='flex-end' mt='32px'>
                <Link to={link}>
                  <Button
                    bg='apryseIndigo'
                    color='white'
                    border='2px solid transparent'
                    disabled={link.length === 0}
                    _disabled={{ opacity: 0.1 }}
                    _hover={{ bg: 'apryseIndigo', color: 'white' }}
                    _focus={{ border: '2px solid #00E2EA' }}
                  >
                    Get Started
                  </Button>
                </Link>
              </Flex>
            </Box>
          </Box>
          <Flex
            gap='24px'
            flexDir='column'
            w={{ base: '100%', lg: '348px' }}
            flexGrow={{ base: 1, lg: 0 }}
          >
            <Flex bg='white' p='32px' flexDir='column' gap={8}>
              <Text color='black' fontWeight='semibold' fontSize='18px'>
                Want to connect?
              </Text>
              <Box h='1px' w='100%' bgColor='rgba(0, 0, 0, 0.08)' />
              <IconLink
                to='https://apryse.com/discord?docLink=tryDis'
                text='Chat with us'
              >
                <DiscordRoundIcon />
              </IconLink>
              <IconLink
                to='https://apryse.com/form/contact-sales?docLink=trySal'
                text='Contact Sales'
              >
                <ContactSalesRoundedIcon />
              </IconLink>
            </Flex>
            <Flex
              bg='linear-gradient(157.45deg, #0206A8 50%, rgba(2, 6, 168, 0.8) 100%)'
              p='32px'
              flexDir='column'
              gap={8}
            >
              <Text color='white' fontWeight='semibold' fontSize='18px'>
                {feedback.title}
              </Text>
              <Box h='1px' w='100%' bgColor='#EEEEEE' />
              {feedback.customers.map((f, index) => (
                <Feedback
                  key={index}
                  src={f.src}
                  feedback={f.feedback}
                  company={f.company}
                  name={f.name}
                />
              ))}
            </Flex>
          </Flex>
        </Flex>
      </ContentContainer>
    </Box>
  );
}
