import React, { ReactNode, CSSProperties} from 'react';
import './ContentContainer.scss';
import './ContentContainer.mobile.scss';
import classNames from 'classnames';

type ContentContainerProps = {
  children: ReactNode;
  className?: string;
  header?: boolean;
  gray?: boolean;
  lightBlue?: boolean;
  tall?: boolean;
  style?: Partial<CSSProperties>;
  noPadding?: boolean;
  wrapperClassName?: string;
  bgImage?: string;
  bgColor?: string;
  noVerticalPadding?: boolean;
  innerStyle?: Partial<CSSProperties>
}

/**
 * A nested wrapper component that places contents inside a fixed width div.
 * The outer div will span the entire width of the page, so that you can apply full width background colors.
 * The inner div will be a fixed width, and will always be centered within the outer div
 */
const ContentContainer = ({
  children,
  className,
  header,
  gray,
  lightBlue,
  tall,
  style = {},
  noPadding,
  wrapperClassName,
  bgImage,
  bgColor,
  noVerticalPadding,
  innerStyle = {}
}: ContentContainerProps) => {

  const c = classNames({
    ContentContainer: true,
    header,
    lightBlue,
    noPadding,
    noVerticalPadding,
    tall,
    [`${className}`]: !!className
  })

  const oc = classNames({
    ContentContainerWrapper: true,
    gray: gray,
    lightBlue,
    tall,
    noPadding,
    noVerticalPadding,
    header: header,
    [wrapperClassName]: !!wrapperClassName
  });

  if (bgImage) {
    style.backgroundImage = `url('${bgImage}')`;
    style.backgroundSize = 'cover'
  }

  if (bgColor) {
    style.backgroundColor = bgColor;
  }

  return (
    
    <div className={oc} style={style} >
      <div className={c} style={innerStyle}>
        {children}
      </div>
    </div>
  )
}

export default ContentContainer;
